import React from 'react'
import HelpCenterCategory from '../helpCenterCategory/HelpCenterCategory'
import NavStatic from '../../containers/navStatic/NavStatic'
import Layout from "../../components/layout";
import Footer from '../footer/Footer'
import './HelpCenterLayout.css'

const HelpCenterLayout = ({data, header}) => {
    const content = data.allMdx.edges
    return (
        <Layout>
            <NavStatic/>
            <div className='helpCenterLayout-container'>
                <h2 className='helpCenterLayout-header'>{header}</h2>
                <div className='helpCenterLayout-grid'>
                    {data.allMdx.distinct.map((e)=>{
                    return <HelpCenterCategory key={e} category={e} content={content}/>
                        })}
                </div>

            </div>
         
            <Footer/>
        </Layout>

   
      
    )
}

export default HelpCenterLayout
