import React from 'react'
import { Link } from "gatsby"
import './HelpCenterCategory.css'

const HelpCenterCategory = ({category,content}) => {
    console.log(content)
    const filterContent = content.filter(e=>{
        if(e.node.frontmatter.category === category){
            return true
        }else{
            return false
        }
    })
    console.log(filterContent)
    return (
        <div className="helpCenterCategory-container">
            <h3 className='helpCenterCategory-subHeading'>
                {category}
            </h3>
        
            {filterContent.map((element)=>{
                return <Link className='helpCenterCategory-content ' to={`/${element.node.slug}`}>{element.node.frontmatter.title}</Link>
            })}
 

        </div>
    )
}

export default HelpCenterCategory

