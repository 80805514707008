import React from 'react'
import { graphql } from "gatsby"
import HelpCenterLayout from '../components/helpCenterLayout/HelpCenterLayout'


const MerchantHelpCenter = ({data}) => {
    console.log(data)
    return (
        <div>
          <HelpCenterLayout data ={data} header='Merchant Help Center'/>
        </div>
    )
}

export default MerchantHelpCenter

export const query = graphql`
  query {
    allMdx(filter:{slug:{regex:"/merchant-hc/"}}) {
        edges{
         node{
          slug
          frontmatter{
              title
              category
          }
         }
       }
       }
    allMdx(filter:{slug:{regex:"/merchant-hc/"}}) {
        distinct(field:frontmatter___category)
        }
  }
`